/// <summary>
/// File Name : indexapi.js
/// Created By : 
/// Purpose :  Configuring the  urls  to connect to the backend  or any login pages
/// </summary>
/// <returns></returns>
// export const dev_url="https://localhost:44300/"

// Dev Configuration
// export const dev_url = "https://cdvalet-dashboard-backend.azurewebsites.net/"; //  backend connected url 
// export const azure_cd_valet = "https://authdev.cdvalet.com/"; //user detals api
// export  const login_redirect_url="https://account.cdvalet.com/cdvaletconsumer.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Sign-in&client_id=0cccdb9a-36f3-4d61-a0ef-ad518b362af3&nonce=defaultNonce&redirect_uri=https://cdvalet-dashboard.azurewebsites.net/list&scope=openid&response_type=id_token&prompt=login" // redirect url login
// export const  fidev_url="https://fidev.cdvalet.com/"//bi dashboard url
// export const dev_url="https://localhost:44300/"

/* Commented config vars as added in Azure
export const dev_url = "https://intelligencetoolapi.cdvalet.com/"; //  backend connected url 
export const azure_cd_valet = "https://auth.cdvalet.com/"; //user detals api
export  const login_redirect_url="https://login.cdvalet.com/cdvaletb2corg.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FORCEPASSWORDRESET_SIGNIN&client_id=454e9883-eb63-4502-b688-66d2ec20a908&nonce=defaultNonce&redirect_uri=https://intelligencetool.cdvalet.com/list&scope=openid&response_type=id_token&prompt=login" // redirect url login
export const  fidev_url="https://fi.cdvalet.com/"//bi dashboard url
export const Logout_url="https://fi.cdvalet.com/logout"
*/

export const dev_url = process.env.REACT_APP_Intelligencetoolapi_url; //  backend connected url 
export const azure_cd_valet = process.env.REACT_APP_cdvalet_auth_url; //user detals api
export const login_redirect_url = process.env.REACT_APP_ad_b2c_login_url; // redirect url login
export const fidev_url = process.env.REACT_APP_cdvalet_fiweb_url;//bi dashboard url
export const Logout_url = process.env.REACT_APP_cdvalet_fiweb_logout_url;