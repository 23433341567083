// RedirectURL.js
import React from 'react';

const RedirectURL = (props) => {
 

  return (
    <div>
       <h1>Test</h1>
    </div>
  );
};

export default RedirectURL;
