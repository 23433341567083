import { useEffect } from 'react';
import { Logout_url, login_redirect_url } from './config/indexapi';

const Logout = () => {
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('username');
      localStorage.removeItem('list_token');
      localStorage.removeItem('id_token');
      window.open(`${login_redirect_url}`, '_self', 'noreferrer');
      }, 2000);
    
  }, []);

  return (
    <>
      <iframe
        title="Loadder"
        width="100%"
        height="0"
        src={Logout_url}
        style={{ visibility: 'hidden' }}
      ></iframe>
      <h3 className="text-center mt-2">
        You are being logout
        <br /> Please wait...
      </h3>
    </>
  );
};

export default Logout;
